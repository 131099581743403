// https://material.angular.io/guide/typography



$desktop-typography: mat-typography-config(
  $font-family: '\'Source Sans Pro\', sans-serif',
  /* headers */
  $headline: mat-typography-level(56px, 48px, 900, $letter-spacing: normal), /* h1.mat-h1 */
  $title: mat-typography-level(40px, 48px, 700), /* h2.mat-h2 */
  $subheading-2: mat-typography-level(24px, 32px, 700),  /* h3.mat-h3 */
  $subheading-1: mat-typography-level(20px, 24px, 700), /* h4.mat-h4 */

  /* body */
  $body-1: mat-typography-level(16px, 24px, 400),
  $body-2: mat-typography-level(20px, 24px, 300),
  $caption: mat-typography-level(12px, 20px, 700),
  /* misc */
  $input: mat-typography-level(16px, 1.125, 400)
);

$tablet-typography: mat-typography-config(
  $font-family: '\'Source Sans Pro\', sans-serif',
  /* headers */
  $headline:     mat-typography-level(42px, 36px, 900, $letter-spacing: normal),  /* h1.mat-h1 */
  $title: mat-typography-level(24px, 30px, 700), /* h2.mat-h2 */
  $subheading-2: mat-typography-level(20px, 30px, 700), /* h3.mat-h3 */
  $subheading-1	: mat-typography-level(16px, 24px, 700), /* h4.mat-h4 */
  /* body */
  $body-1: mat-typography-level(16px, 24px, 400),
  $body-2: mat-typography-level(20px, 24px, 300),
  $caption: mat-typography-level(12px, 20px, 700),
  /* misc */
  $input: mat-typography-level(16px, 1.125, 400)
);

$mobile-typography: mat-typography-config(
  $font-family: '\'Source Sans Pro\', sans-serif',
  /* headers */
  $headline:     mat-typography-level(30px, 30px, 900, $letter-spacing: normal),  /* h1.mat-h1 */
  $title: mat-typography-level(24px, 30px, 700), /* h2.mat-h2 */
  $subheading-2: mat-typography-level(20px, 30px, 700), /* h3.mat-h3 */
  $subheading-1	: mat-typography-level(16px, 24px, 700), /* h4.mat-h4 */

  /* body */
  $body-1: mat-typography-level(16px, 24px, 400),
  $body-2: mat-typography-level(20px, 24px, 300),
  $caption: mat-typography-level(12px, 20px, 700),
  /* misc */
  $input: mat-typography-level(16px, 1.125, 400)
);

@mixin typograhpy() {
  @media screen and (max-width: 599.99px) and (orientation: portrait), (max-width: 959.99px) and (orientation: landscape) {
    @include angular-material-typography($mobile-typography);
  }
  @media screen and (min-width: 600px) and (max-width: 839.99px) and (orientation: portrait), (min-width: 960px) and (max-width: 1279.99px) and (orientation: landscape) {
    @include angular-material-typography($tablet-typography);
  }
  @media screen and (min-width: 840px) and (orientation: portrait), (min-width: 1280px) and (orientation: landscape) {
    @include angular-material-typography($desktop-typography);
  }
}